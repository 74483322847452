import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "./layout-light";
import ImageLink from "./image-link";

const query = graphql`
  query {
    videosCtaSm: file(
      relativePath: { eq: "shared/confirmation/cta-videos-sm.jpg" }
    ) {
      ...CtaImageSm
    }
    videosCtaLg: file(
      relativePath: { eq: "shared/confirmation/cta-videos.jpg" }
    ) {
      ...CtaImageLg
    }

    reviewsCtaSm: file(
      relativePath: { eq: "shared/confirmation/cta-reviews-sm.jpg" }
    ) {
      ...CtaImageSm
    }
    reviewsCtaLg: file(
      relativePath: { eq: "shared/confirmation/cta-reviews.jpg" }
    ) {
      ...CtaImageLg
    }

    inspirationCtaSm: file(
      relativePath: { eq: "shared/confirmation/cta-inspiration-sm.jpg" }
    ) {
      ...CtaImageSm
    }
    inspirationCtaLg: file(
      relativePath: { eq: "shared/confirmation/cta-inspiration.jpg" }
    ) {
      ...CtaImageLg
    }
  }
  fragment CtaImageSm on File {
    childImageSharp {
      fluid(maxWidth: 670, maxHeight: 360) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment CtaImageLg on File {
    childImageSharp {
      fluid(maxWidth: 860, maxHeight: 760) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default function ConfirmationLayout({ homeUrl }) {
  return (
    <Layout homeUrl={homeUrl}>
      <div className="content-container-max">
        <h1 className="text-blue-900 text-xl text-center mt-10 md:text-4xl md:max-w-screen-lg lg:px-20 md:mx-auto md:mt-20">
          Your request has been received and we’ll be in touch with you shortly.
        </h1>
        <p className="text-blue-500 text-sm mt-3 leading-tight text-center md:mt-12 md:leading-normal md:text-2xl md:max-w-2xl md:mx-auto">
          In the meantime, explore more about what it’s like to own the Outer
          products
        </p>
        <StaticQuery
          query={query}
          render={data => (
            <div className="mt-10 md:flex md:mt-20 md:-mx-5">
              <ImageLink
                title="Videos"
                href="https://www.youtube.com/channel/UCWjPe5z2T-CJCft2ePXk_Gw/videos"
                sources={[
                  data.videosCtaSm.childImageSharp.fluid,
                  {
                    ...data.videosCtaLg.childImageSharp.fluid,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />

              <ImageLink
                title="Inspiration"
                href="https://liveouter.com/inspiration"
                sources={[
                  data.inspirationCtaSm.childImageSharp.fluid,
                  {
                    ...data.inspirationCtaLg.childImageSharp.fluid,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />

              <ImageLink
                title="Reviews"
                href="https://liveouter.com/reviews"
                sources={[
                  data.reviewsCtaSm.childImageSharp.fluid,
                  {
                    ...data.reviewsCtaLg.childImageSharp.fluid,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </div>
          )}
        />
      </div>
    </Layout>
  );
}
