import Img from "gatsby-image";
import React from "react";

export default function ImageLink({ title, sources, href }) {
  return (
    <a
      className={`relative block mb-3 md:w-1/3 md:mx-5 overflow-hidden bg-black`}
      href={href}
    >
      <Img
        alt={title}
        className="md:mt-auto block transform md:hover:scale-110 md:hover:opacity-30 duration-300"
        style={{ filter: "brightness(60%)" }}
        fluid={sources}
      />
      <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white-900 text-xl lg:text-3xl pointer-events-none">
        {title}
        <span className="block w-8 border block mx-auto mt-3 md:w-12" />
      </span>
    </a>
  );
}
