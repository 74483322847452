import { Link } from "gatsby";
import outerLogoSvg from "../../images/owner-since/outer-logo.svg";
import React from "react";

import "./index.scss";

function Logo({ homeUrl }) {
  return (
    <Link to={homeUrl} className="p-1 w-16 md:w-auto md:p-0.5">
      <img className="mx-auto" src={outerLogoSvg} />
    </Link>
  );
}

export default function LayoutLight({ children, homeUrl }) {
  const year = new Date().getFullYear();
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-center p-4 border-solid border-b border-blue-300 items-center">
        <Logo homeUrl={homeUrl} />
      </div>
      {children}
      <footer className="mt-16 py-10 border-solid border-t border-blue-300 text-colors-outer-slate lg:mt-20">
        <div className="content-container-max flex flex-wrap justify-between items-center content-center">
          <p className="text-sm text-colors-outer-slate order-2 sm:order-1 sm:w-1/3">
            Outer&copy; {year}
          </p>
          <div className="hidden order-2 sm:block sm:w-1/3 text-center">
            <Logo homeUrl={homeUrl} />
          </div>
          <p className="text-sm order-1 sm:order-3 text-right sm:w-1/3">
            <a href="https://liveouter.com/privacy">Privacy Policy</a>
            <a className="ml-5" href="https://liveouter.com/terms">
              Terms of Use
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}
