import { useEffect } from "react";

export const useFunnelTracking = function (funnelName: string) {
  useEffect(() => {
    track("Funnel Start", { name: funnelName });
  }, []);

  return [track];
};
const mergeBaseAnalyticsProps = function (props) {
  if (typeof props != "object") {
    props = {};
  }
  return props;
};

export const track = (name, properties = {}, options = {}) => {
  properties = mergeBaseAnalyticsProps(properties);
  window.analytics.track(name, properties, options);
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    console.log("TRACK", name, properties, options);
  }
};

interface IdentifyTraits {
  anonymousId?: string;
  email: string;
}

export const identify = (
  userId: string | undefined,
  traits: IdentifyTraits = { anonymousId: undefined, email: undefined },
  options = {}
) => {
  window.analytics.identify(traits, options);
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    console.log("IDENTIFY", userId, traits);
  }
};
